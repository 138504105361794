/** @jsx jsx*/
import { jsx, Heading } from "theme-ui";
import Img from "gatsby-image";
import { Fragment, useContext } from "react";
import { AppContext } from "../../context/app-context";
import { Linkedin } from "../../icons";

export function Worker({ workers, title }) {
  const { locale } = useContext(AppContext);
  const administrativos = workers.filter(
    (worker) => worker.department === "administrativo"
  );
  const consultores = workers.filter(
    (worker) => worker.department === "consultores"
  );
  return (
    <div
      sx={{
        bg: "inherit",
        px: [2, 3, 4, 5],
        py: [4, null, null, 5],
        maxWidth: ["960px", null, null, null, "1280px"],
        mx: "auto",
      }}
    >
      <Heading
        as="h2"
        sx={{
          fontSize: [4, null, null, 6],
          color: "header",
          textAlign: "center",
          mb: [4, null, null, 5],
        }}
      >
        {title}
      </Heading>
      <Fragment>
        <Heading as="h3" sx={{ p: 3, textAlign: "center", fontSize: [3] }}>
          {locale === "es-PE" ? "Administrativos" : "Managers"}
        </Heading>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(128px,1fr))",
            placeItems: "center",
            gap: [2, null, null, 4],
          }}
        >
          {administrativos.map((worker) => (
            <div
              sx={{ display: "grid", gap: 2, placeItems: "center" }}
              key={worker.name}
            >
              <div
                sx={{
                  width: "128px",
                  height: "128px",
                  boxShadow:
                    "0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)",
                  borderRadius: "100%",
                  overflow: "hidden ",
                }}
              >
                <Img fluid={worker.photo.fluid} alt={worker.photo.position} />
              </div>
              <Heading
                as="h4"
                sx={{
                  fontSize: [0, null, null, 2],
                  textTransform: "uppercase",
                  textAlign: "center",
                }}
              >
                {worker.name}
              </Heading>
              <p sx={{ fontSize: 0, textAlign: "center", m: 0 }}>
                {worker.position}
              </p>
              <a
                rel="noopener"
                href={
                  worker.linkedin
                    ? worker.linkedin
                    : "https://www.linkedin.com/"
                }
                target="_blank"
                sx={{ color: "header" }}
              >
                <span sx={{ display: "none" }}>Linkedin</span>
                <Linkedin />
              </a>
            </div>
          ))}
        </div>
      </Fragment>
      <Fragment>
        <Heading
          as="h3"
          sx={{ mt: 5, p: 3, textAlign: "center", fontSize: [3] }}
        >
          {locale === "es-PE" ? "Consultores" : "Consultants"}
        </Heading>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(128px,1fr))",
            placeItems: "center",
            gap: [2, null, null, 4],
          }}
        >
          {consultores.map((worker) => (
            <div
              sx={{ display: "grid", gap: 2, placeItems: "center" }}
              key={worker.name}
            >
              <div
                sx={{
                  width: "128px",
                  height: "128px",
                  boxShadow:
                    "0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)",
                  borderRadius: "100%",
                  overflow: "hidden ",
                }}
              >
                <Img fluid={worker.photo.fluid} alt={worker.photo.position} />
              </div>
              <Heading
                as="h4"
                sx={{
                  fontSize: [0, null, null, 2],
                  textTransform: "uppercase",
                  textAlign: "center",
                }}
              >
                {worker.name}
              </Heading>
              <p sx={{ fontSize: 0, textAlign: "center", m: 0 }}>
                {worker.position}
              </p>
              <a
                rel="noopener"
                href={
                  worker.linkedin
                    ? worker.linkedin
                    : "https://www.linkedin.com/"
                }
                target="_blank"
                sx={{ color: "header" }}
              >
                <span sx={{ display: "none" }}>Linkedin</span>
                <Linkedin />
              </a>
            </div>
          ))}
        </div>
      </Fragment>
    </div>
  );
}
