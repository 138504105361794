/**@jsx jsx */
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Heading, jsx } from "theme-ui";
import { Hero } from "../components/distribution/hero";
import { Layout } from "../components/layout/main-layout";
import { SliderClients } from "../components/common/slider/slider-clients";
import { Worker } from "../components/distribution/worker";

export default function AboutTemplate({ data }) {
  const { image, page_content, title } = data.contentfulPage;
  const workers = data.workers.nodes;
  const clients = data.clients.nodes;
  const sortedPages = page_content?.sort((a, b) =>
    a.order > b.order ? 1 : a.order < b.order ? -1 : 0
  );
  return (
    <Layout>
      <Hero image={image.fluid} title={title} />
      <div
        sx={{
          display: [null, "grid"],
          gridTemplateColumns: [null, "1fr 1fr 1fr"],
        }}
      >
        {sortedPages?.map((page) => {
          const description =
            page.content !== null &&
            documentToReactComponents(page.content.json);

          if (page.title === "Nuestro Equipo" || page.title === "Our Team") {
            return (
              <div
                sx={{ gridColumn: [null, "1/4"], bg: "footer" }}
                key={page.id}
              >
                <Worker workers={workers} title={page.title} />
              </div>
            );
          }
          if (
            page.title === "Nuestros Clientes" ||
            page.title === "Our Clients"
          ) {
            return (
              <div
                sx={{ gridColumn: [null, "1/4"], bg: "background" }}
                key={page.id}
              >
                <SliderClients clients={clients} title={page.title} />
              </div>
            );
          }

          if (page.order === 2) {
            const left =
              page.slug.includes("vision") || page.slug.includes("mision");
            const vision = page.slug.includes("vision");
            const mision = page.slug.includes("mision");
            return (
              <div
                sx={{
                  bg: "background",
                  my: [4, null, null, 6],
                  px: [3, 4, 5],
                  borderRight: [null, left && "1px solid black"],
                  gridColumn: vision ? "1/2" : mision ? "2/3" : "3/4",
                  gridRow: "2/3",
                }}
                key={page.id}
              >
                <Heading
                  sx={{
                    variant: "text.secondaryTitle",
                  }}
                >
                  {page.title}
                </Heading>
                <div sx={{ fontSize: [null, null, null, null, 3] }}>
                  {description}
                </div>
              </div>
            );
          }
          return (
            <div
              key={page.id}
              sx={{
                bg: "footer",
                py: 5,
                px: [3, null, 0],
                gridColumn: "1/4",
              }}
            >
              <Heading
                as="h2"
                sx={{
                  variant: "text.title",
                }}
              >
                {page.title}
              </Heading>
              <div
                sx={{
                  maxWidth: ["960px", null, null, null, "1280px"],
                  mx: "auto",
                  fontSize: [null, null, null, null, 3],
                  display: ["grid"],
                  gridTemplateColumns: [null, "256px 1fr"],
                  gap: [null, null, 4],
                }}
              >
                <div sx={{ placeSelf: "center", width: "100%" }}>
                  <div
                    sx={{
                      borderRadius: "100%",
                      overflow: "hidden",
                      mx: [4],
                      boxShadow:
                        "0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16)",
                    }}
                  >
                    <Img fluid={data.ceo.nodes[0].photo.fluid} />
                  </div>
                  <Heading as="h3" sx={{ textAlign: "center", mt: 2 }}>
                    {data.ceo.nodes[0].name}
                  </Heading>
                  <Heading as="h3" sx={{ textAlign: "center" }}>
                    {data.ceo.nodes[0].position}
                  </Heading>
                </div>
                <div>{description}</div>
              </div>
            </div>
          );
        })}
      </div>
    </Layout>
  );
}

export const query = graphql`
  query($locale: String, $slug: String) {
    contentfulPage(node_locale: { eq: $locale }, slug: { eq: $slug }) {
      title
      slug
      content {
        content
      }
      image {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      page_content {
        id
        title
        slug
        order
        content {
          json
        }
      }
    }
    ceo: allContentfulWorker(
      filter: { name: { eq: "Gian Taboada" }, node_locale: { eq: $locale } }
    ) {
      nodes {
        id
        name
        position
        photo {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        linkedin
      }
    }
    clients: allContentfulClient(filter: { node_locale: { eq: $locale } }) {
      nodes {
        id
        title
        link
        image {
          fluid(quality: 80, maxWidth: 250) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
        }
      }
    }
    workers: allContentfulWorker(
      filter: { node_locale: { eq: $locale } }
      sort: { order: ASC, fields: order }
    ) {
      nodes {
        name
        photo {
          fluid(quality: 80, maxWidth: 250) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        position
        department
        linkedin
      }
    }
  }
`;
